import { useContext } from 'react';
import { settingsStore } from 'stores/settings';

type DateFormat =
    | 'short' // Example: "2024/12/18" (in UK) or "12/18/2024" (in US)
    | 'medium' // Example: "2024/12/18, 13:28" (in UK) or "12/18/2024, 1:28 PM" (in US)
    | 'long' // Example: "28 February, 2024 at 18:59" (in UK) or "February 28, 2024 at 6:59 PM" (in US)
    | 'full'; // Example: "Wednesday 28 February, 2024 at 18:59" (in UK) or "Wednesday February 28, 2024 at 6:59 PM" (in US)

type Options = {
    includeTime?: boolean;
    format?: DateFormat;
};

export const useDateFormatting = () => {
    const { tenantTimezone, language } = useContext(settingsStore);

    const convertToUTC = (dateStr: string | number) => {
        // Split the date and time segments
        const [datePart, timePart] = dateStr.toString().split(' ');
        // Reformat from DD/MM/YYYY to YYYY-MM-DD for ISO format.
        const [year, month, day] = datePart.split('-');
        // Combine into ISO format and add 'Z' to indicate UTC
        return new Date(`${year}-${month}-${day}T${timePart}Z`);
    };

    const tenantDate = (
        date: string | number,
        options: Options = { includeTime: true, format: 'short' }
    ): string => {
        if (!date || typeof date === 'number') {
            return 'N/A';
        }

        // Check if 'date' has the ISO 8601 date format or not ("YYYY-MM-DDTHH:mm:ss.sssZ"):
        const isIsoUtc =
            /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})?$/.test(date);

        // Make it independent of local timezone:
        const utcDate = isIsoUtc ? new Date(date) : convertToUTC(date);

        const shortFormatter = new Intl.DateTimeFormat(language, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: options.includeTime ? '2-digit' : undefined,
            minute: options.includeTime ? '2-digit' : undefined,
            hour12: options.includeTime && language === 'en-US' ? true : false,
            timeZone: tenantTimezone
        });

        const defaultFormatter = new Intl.DateTimeFormat(language, {
            dateStyle: options.format,
            timeStyle: options.includeTime ? 'short' : undefined,
            timeZone: tenantTimezone
        });

        const formattedDate =
            options.format === 'short'
                ? shortFormatter.format(utcDate)
                : defaultFormatter.format(utcDate);

        // Apply the given format string:
        return formattedDate.replace('am', 'AM').replace('pm', 'PM');
    };

    return { tenantDate };
};
