/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { daysOut } from '../common/datepicker/dateUtils';
import { ASLink } from '../common/link';
import { Desktop, Mobile, sizes } from '../responsive/MediaQuery';
import { Entity, EntityMetadata } from 'utils/dataloaders/entityLoader';
import { getEntityLabelOrID } from 'views/dashboards/EventOverview/utils';
import { CustomSvgIcon } from 'utils/CustomSvgIcon';
import { noTilePresentationImage } from 'views/utils/iconHandler';
import { useDateFormatting } from 'utils/date/useDateFormatting';

const EntityMetadataTile = ({ entity }: { entity: Entity }) => {
    const { t } = useTranslation();
    const eventMetadata = entity.entity;
    const eventTraversedRelations: EntityMetadata[] = entity?.traversedRelations || [];
    const entityRef = entity.entity.entity_ref;
    const entityType = entity.entity.archetype;

    const { tenantDate } = useDateFormatting();

    const getDaysFromEventString = (daysOut: number): string =>
        daysOut < 0 ? `${t('pastEvent')}` : `in ${daysOut} ${t('days')}`;

    const eventPerformerRelations = eventTraversedRelations.filter(
        (relation) => relation.archetype === 'Performer'
    );

    const eventProductionRelations = eventTraversedRelations.filter(
        (relation) =>
            relation.archetype === 'ProductGroup' && relation.archetype_variant === 'Production'
    );

    const eventSeasonRelations = eventTraversedRelations.filter(
        (relation) =>
            relation.archetype === 'ProductGroup' && relation.archetype_variant === 'Season'
    );

    const venueLabel =
        eventTraversedRelations.filter((relation) => relation?.archetype_variant === 'Venue')[0]
            ?.aspects?.presentation?.label || '';

    const date =
        eventMetadata.aspects && eventMetadata?.aspects?.timed?.begins?.begins
            ? `${tenantDate(eventMetadata.aspects.timed?.begins.begins, { format: 'full' })}`
            : '';

    const eventLeadTime =
        eventMetadata.aspects && eventMetadata.aspects.timed?.begins?.begins
            ? `(${getDaysFromEventString(daysOut(eventMetadata.aspects.timed?.begins.begins))})`
            : '';

    const thumbnail = eventMetadata.aspects?.presentation?.thumbnail;

    const navigation = () =>
        (eventPerformerRelations && eventPerformerRelations.length) ||
        (eventProductionRelations && eventProductionRelations.length) ||
        (eventSeasonRelations && eventSeasonRelations.length) ? (
            <EntityNavigationWrapper>
                <p>{t('navigation')} </p>
                {eventPerformerRelations && eventPerformerRelations.length > 0
                    ? eventPerformerRelations.map((relation: EntityMetadata, i) => (
                          <EntityMetadataNavigationLink
                              key={relation.entity_ref || i}
                              text={
                                  relation?.aspects?.presentation?.label
                                      ? `${relation?.aspects?.presentation?.label} ${t('performerView')}`
                                      : `${t('unknown')} ${t('performerView')}`
                              }
                              href={`/activity-stream/EntityOverview/${relation.entity_ref}`}
                          />
                      ))
                    : null}
                {eventProductionRelations && eventProductionRelations.length > 0
                    ? eventProductionRelations.map((relation: EntityMetadata, i) => (
                          <EntityMetadataNavigationLink
                              key={relation.entity_ref || i}
                              text={
                                  relation?.aspects?.presentation?.label
                                      ? `${relation?.aspects?.presentation?.label} ${t('productionView')}`
                                      : `${t('unknown')} ${t('productionView')}`
                              }
                              href={`/activity-stream/EntityOverview/${relation.entity_ref}`}
                          />
                      ))
                    : null}
                {eventSeasonRelations && eventSeasonRelations.length > 0
                    ? eventSeasonRelations.map((relation: EntityMetadata, i) => (
                          <EntityMetadataNavigationLink
                              key={relation.entity_ref || i}
                              disabled={true}
                              text={
                                  relation?.aspects?.presentation?.label
                                      ? `${relation?.aspects?.presentation?.label} ${t('seasonView')}`
                                      : `${t('unknown')} ${t('seasonView')}`
                              }
                              href={`/activity-stream/EntityOverview/${relation.entity_ref}`}
                          />
                      ))
                    : null}
            </EntityNavigationWrapper>
        ) : null;

    return entity && eventMetadata.aspects ? (
        <Wrapper>
            <EntityDetailsTileWrapper>
                {thumbnail ? (
                    <Image thumbnail={thumbnail} />
                ) : (
                    <CustomSvgIconWrapper entityType={entityType}>
                        <CustomSvgIcon
                            fill=""
                            size={4}
                            iconName={noTilePresentationImage(entityRef) as string}
                        />
                    </CustomSvgIconWrapper>
                )}
                <EntityDetailsTextWrapper>
                    <LeftSection>
                        <EntityDetailsLabel>{getEntityLabelOrID(eventMetadata)}</EntityDetailsLabel>
                        <EntityDetailsDateVenue>
                            <span>
                                {date}
                                <LeadTimeSpan>{eventLeadTime}</LeadTimeSpan>
                            </span>{' '}
                            <Location>{venueLabel}</Location>
                        </EntityDetailsDateVenue>
                        <Desktop>{navigation()}</Desktop>
                    </LeftSection>
                    <RightSection>
                        <p>
                            {eventMetadata.aspects.classification?.type ? (
                                <span>
                                    {t('category')}:{' '}
                                    <CategorySpan>
                                        {eventMetadata.aspects.classification?.type}
                                    </CategorySpan>
                                </span>
                            ) : (
                                ''
                            )}
                        </p>
                        <p>
                            {(eventMetadata?.aspects?.classification?.categories || []).length >
                            0 ? (
                                <span>
                                    {t('subCategory')}:{' '}
                                    <CategorySpan>
                                        {eventMetadata?.aspects?.classification?.categories?.[0] ||
                                            ''}
                                    </CategorySpan>
                                </span>
                            ) : (
                                ''
                            )}
                        </p>
                    </RightSection>
                </EntityDetailsTextWrapper>
            </EntityDetailsTileWrapper>
            <Mobile>{navigation()}</Mobile>
        </Wrapper>
    ) : null;
};

export { EntityMetadataTile };

type ImageProps = { thumbnail: string };

const Wrapper = styled('div')``;

const Image = styled('div')<ImageProps>`
    display: flex;
    flex-shrink: 0;
    align-self: center;
    width: 180px;
    height: 180px;
    background: ${({ thumbnail, theme }) =>
        thumbnail
            ? `url(${thumbnail}) no-repeat center center `
            : theme.colors.toplistNoCustomerImage};
    background-size: cover;
    @media (max-width: ${sizes.desktop.minWidth}px) {
        height: 82px;
        width: 82px;
    }
    border-radius: ${({ theme }) => theme.borderRadius.small};
`;

const CustomSvgIconWrapper = styled('div')<{ entityType?: string | null }>`
    display: flex;
    flex-shrink: 0;
    align-self: center;
    width: 180px;
    height: 180px;
    background: ${({ theme, entityType }) =>
        //this logic needs to be changed since we have background variations for Reseller and Concert
        entityType && entityType === 'Donor'
            ? theme.colors.toplistNoDonorImage
            : theme.colors.toplistNoCustomerImage};

    @media (max-width: ${sizes.desktop.minWidth}px) {
        height: 82px;
        width: 82px;
    }
`;

const EntityDetailsTileWrapper = styled('div')`
    display: flex;
    padding-bottom: 0;
    height: 100%;
    width: 100%;
    justify-self: start;
    grid-auto-flow: column;
    position: relative;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.borderRadius.small};
`;

const LeftSection = styled('div')`
    line-height: 1.2;

    @media (max-width: ${sizes.desktop.minWidth}px) {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: ${({ theme }) => theme.space.half} 0;
    }
`;

const RightSection = styled('div')`
    padding-left: ${({ theme }) => theme.space.single};
    font-size: ${({ theme }) => theme.fontSizes.small};

    @media (max-width: ${sizes.desktop.minWidth}px) {
        padding-top: ${({ theme }) => theme.space.half};
        padding-bottom: ${({ theme }) => theme.space.half};
    }
`;

const EntityDetailsTextWrapper = styled('div')`
    padding-top: ${({ theme }) => theme.space.double};
    padding-right: ${({ theme }) => theme.space.double};
    padding-left: ${({ theme }) => theme.space.double};
    display: grid;
    grid-template-columns: 8fr 1fr;
    width: 100%;
    height: 180px;
    border: 1px solid ${({ theme }) => theme.colors.frame};
    border-left: none;

    @media (max-width: ${sizes.desktop.minWidth}px) {
        height: unset;
        padding-top: 0;
        padding-left: ${({ theme }) => theme.space.single};
        padding-right: 0;
        display: flex;
    }
`;

const EntityDetailsLabel = styled('p')`
    font-size: 38px;
    color: ${({ theme }) => theme.colors.textLink};
    font-weight: 700;

    @media (max-width: ${sizes.desktop.minWidth}px) {
        font-size: ${({ theme }) => theme.fontSizes.small};
    }
`;

const EntityDetailsDateVenue = styled('p')`
    display: flex;
    flex-direction: row;
    font-size: ${({ theme }) => theme.fontSizes.big};

    @media (max-width: ${sizes.desktop.minWidth}px) {
        flex-direction: column;
        font-size: ${({ theme }) => theme.fontSizes.small};
    }
`;

const Location = styled('p')`
    font-size: ${({ theme }) => theme.fontSizes.big};

    @media (max-width: ${sizes.desktop.minWidth}px) {
        font-size: ${({ theme }) => theme.fontSizes.small};
        margin-top: 4px;
    }
`;

const EntityNavigationWrapper = styled('div')`
    padding-top: 18px;
    font-weight: 500;
`;

const EntityMetadataNavigationLink = styled(ASLink)`
    &:first-of-type {
        border-left: none;
        padding-left: 0px;
    }
    border-left: 1px solid ${({ theme }) => theme.colors.textLink};
    padding: 0 ${({ theme }) => theme.space.single};
    line-height: 2.2;
    font-size: ${({ theme }) => theme.fontSizes.normal};
    font-weight: 400;
    text-decoration: underline;
    display: inline;

    & .MuiLink-root {
        cursor: pointer;
    }
`;

const LeadTimeSpan = styled('span')`
    color: ${({ theme }) => theme.variables['--icon-colorTwo']};
    margin-left: ${({ theme }) => theme.space.half};

    @media (min-width: ${sizes.desktop.minWidth}px) {
        &::after {
            content: '-';
            margin-left: ${({ theme }) => theme.space.half};
            margin-right: ${({ theme }) => theme.space.half};
            color: ${({ theme }) => theme.colors.black};
        }
    }
`;

const CategorySpan = styled('span')`
    font-weight: bold;
`;
