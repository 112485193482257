import React from 'react';
import { pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { ASLink } from '../common/link';
import { LoadingContainer } from '../common/loader';
import { ASTable } from '../common/table';
import {
    getEventStatisticTableDataEvents,
    getEventStatisticTableDataDonations,
    getEventStatisticTableDataProducts
} from '../common/table/queries';
import {
    EventStatisticsTableProps,
    EventStatistic,
    Row,
    Sorting,
    Pagination,
    OnChange,
    OnChangeParams,
    Direction
} from '../common/table/types';
import { prepareCustomerViewTablePage } from '../common/table/utils';
import { DimensionData } from 'utils/common/types';
import { settingsStore } from 'stores/settings';
import { formatCurrency2Decimals } from 'utils/formatting/currency';
import { cubejsApi } from 'utils/api/CubeAPI';
import {
    TRANSACTIONS_HALL_LABEL,
    TRANSACTIONS_PRODUCT_LABEL,
    TRANSACTIONS_PRODUCT_TIME_BEGINS,
    DESC,
    BASE_TRANSACTIONS_CURRENCY,
    BASE_TRANSACTIONS_ORDER_REF,
    BASE_TRANSACTIONS_OCCURRED_AT,
    BASE_TRANSACTIONS_PRODUCT_REF,
    BASE_TRANSACTIONS_ITEM_COUNT,
    BASE_TRANSACTIONS_REVENUE,
    BASE_TRANSACTIONS_PRODUCT_ARCHETYPE_VARIANT,
    DONATIONS_FUND,
    MERCHANDISE_PRODUCT_LABEL,
    MERCHANDISE_PRODUCT_DESCRIPTION,
    ORDER_EXTERNAL_DATA_ID
} from 'utils/common/constants';
import { useDateFormatting } from 'utils/date/useDateFormatting';

const EventStatisticsTable = ({
    salesTime,
    metric,
    timePeriod,
    filters,
    baseFilters
}: EventStatisticsTableProps) => {
    const { t } = useTranslation();
    const { tenantTimezone } = React.useContext(settingsStore);
    const [page, setPage] = React.useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
    const [orderBy, setOrderBy] = React.useState<keyof EventStatistic>('date');
    const [orderDirection, setOrderDirection] = React.useState<Direction>(DESC);
    const [rawData, setRawData] = React.useState<DimensionData[]>([]);
    const [events, setEvents] = React.useState<EventStatistic[]>([]);
    const [filteredEvents, setFilteredEvents] = React.useState<EventStatistic[]>([]);
    const [tableData, setTableData] = React.useState<Row[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [currency, setCurrency] = React.useState<string>('');
    const [currentFilter, setCurrentFilter] = React.useState<string>('All');
    const [availableFilters, setAvailableFilters] = React.useState<string[]>([]);

    const { tenantDate } = useDateFormatting();

    const filterAlias: { [key: string]: string } = {
        All: t('all'),
        Event: t('ticketSales'),
        Fund: t('donations'),
        'Product Sales': t('productSales')
    };

    React.useEffect(() => {
        setLoading(true);
        Promise.all([
            cubejsApi.load(
                getEventStatisticTableDataEvents(
                    salesTime,
                    timePeriod,
                    filters,
                    baseFilters,
                    tenantTimezone
                )
            ),
            cubejsApi.load(
                getEventStatisticTableDataDonations(
                    salesTime,
                    timePeriod,
                    filters,
                    baseFilters,
                    tenantTimezone
                )
            ),
            cubejsApi.load(
                getEventStatisticTableDataProducts(
                    salesTime,
                    timePeriod,
                    filters,
                    baseFilters,
                    tenantTimezone
                )
            )
        ])
            .then((response) => {
                const eventsResponse = pathOr<DimensionData[]>(
                    [],
                    ['loadResponse', 'results', '0', 'data'],
                    response[0]
                );
                const donationsResponse = pathOr<DimensionData[]>(
                    [],
                    ['loadResponse', 'results', '0', 'data'],
                    response[1]
                );
                const productsResponse = pathOr<DimensionData[]>(
                    [],
                    ['loadResponse', 'results', '0', 'data'],
                    response[2]
                );
                setRawData(eventsResponse.concat(donationsResponse, productsResponse));
            })
            .finally(() => {
                setLoading(false);
            });
    }, [salesTime, timePeriod, filters, metric]);

    React.useEffect(() => {
        if (rawData.length > 0) {
            setCurrency(rawData?.[0][BASE_TRANSACTIONS_CURRENCY] as string);
        }
        const responseAvailableFilters: string[] = [];
        setEvents(
            rawData.map((data): any => {
                if (
                    !responseAvailableFilters.includes(
                        data[BASE_TRANSACTIONS_PRODUCT_ARCHETYPE_VARIANT] as string
                    )
                ) {
                    responseAvailableFilters.push(
                        data[BASE_TRANSACTIONS_PRODUCT_ARCHETYPE_VARIANT] as string
                    );
                }
                const externalOrderId = data[ORDER_EXTERNAL_DATA_ID]
                    ? String(data[ORDER_EXTERNAL_DATA_ID])
                    : '';
                const orderId = !!externalOrderId?.length
                    ? externalOrderId
                    : (String(data[BASE_TRANSACTIONS_ORDER_REF]).split('/')[1] ?? t('N/A'));
                return {
                    archetypeVariant: data[BASE_TRANSACTIONS_PRODUCT_ARCHETYPE_VARIANT],
                    order: orderId,
                    date:
                        data[BASE_TRANSACTIONS_OCCURRED_AT] !== null
                            ? tenantDate(data[BASE_TRANSACTIONS_OCCURRED_AT])
                            : t('N/A'),
                    details: getOrderDetails(data),
                    quantity: String(data[BASE_TRANSACTIONS_ITEM_COUNT]) || t('N/A'),
                    revenue: Number(data[BASE_TRANSACTIONS_REVENUE]) || 0
                };
            })
        );

        let groupedFilters: Array<string> = responseAvailableFilters;
        if (groupedFilters.includes('Merchandise') || groupedFilters.includes('Refreshments')) {
            groupedFilters = responseAvailableFilters.filter(
                (filter) => filter !== 'Merchandise' && filter !== 'Refreshments'
            );
            groupedFilters.push('Product Sales');
        }

        setAvailableFilters(['All'].concat(groupedFilters));
    }, [rawData]);

    React.useEffect(() => {
        setFilteredEvents(
            currentFilter !== 'All'
                ? events.filter((event) => {
                      if (currentFilter === 'Product Sales') {
                          return (
                              event.archetypeVariant === 'Refreshments' ||
                              event.archetypeVariant === 'Merchandise'
                          );
                      }
                      return currentFilter === event.archetypeVariant;
                  })
                : events
        );
    }, [events, currentFilter]);

    React.useEffect(() => {
        setTableData(
            prepareCustomerViewTablePage(filteredEvents, orderBy, orderDirection, page, rowsPerPage)
        );
    }, [filteredEvents]);

    const sorting: Sorting = {
        orderBy,
        orderDirection
    };

    const pagination: Pagination = {
        page,
        rowsPerPage,
        rowsPerPageOptions: [5, 10, 25],
        totalRowCount: filteredEvents.length
    };

    const onchangePage: OnChange = ({
        newPage = page,
        newRowsPerPage = rowsPerPage,
        newOrderBy = orderBy,
        newOrderDirection = orderDirection
    }: OnChangeParams) => {
        setTableData(
            prepareCustomerViewTablePage(
                filteredEvents,
                newOrderBy as keyof EventStatistic,
                newOrderDirection,
                newPage,
                newRowsPerPage
            )
        );

        if (newPage !== page) {
            setPage(newPage);
        }
        if (newRowsPerPage !== rowsPerPage) {
            setRowsPerPage(newRowsPerPage);
        }
        if (newOrderBy !== orderBy) {
            setOrderBy(newOrderBy as keyof EventStatistic);
        }
        if (newOrderDirection !== orderDirection) {
            setOrderDirection(newOrderDirection);
        }
    };

    const headers = [
        { label: t('order'), column: 'order' },
        {
            label: t('date'),
            column: 'date'
        },
        { label: t('details'), column: 'details' },
        { label: t('quantity'), column: 'quantity', textAlign: 'right' },
        {
            label: t('revenue'),
            column: 'revenue',
            transform: function ({ revenue }: { revenue: number }) {
                return formatCurrency2Decimals(currency, revenue);
            },
            textAlign: 'right'
        }
    ];

    const getOrderDetails = (data: DimensionData) => {
        const archetypeVariant = String(data[BASE_TRANSACTIONS_PRODUCT_ARCHETYPE_VARIANT]);
        if (archetypeVariant === 'Event') {
            const eventLabel = String(data[TRANSACTIONS_PRODUCT_LABEL]);
            const eventRef = String(data[BASE_TRANSACTIONS_PRODUCT_REF]);
            const productLabel = (
                <ASLink
                    href={`/activity-stream/EntityOverview/${eventRef}`}
                    text={eventLabel ? eventLabel : eventRef.split('/')[1]}
                />
            );

            const eventBegins = data[TRANSACTIONS_PRODUCT_TIME_BEGINS]
                ? `${tenantDate(data[TRANSACTIONS_PRODUCT_TIME_BEGINS])}`
                : '';
            const eventVenue = data[TRANSACTIONS_HALL_LABEL]
                ? `${data[TRANSACTIONS_HALL_LABEL]}`
                : '';
            return (
                <div>
                    {productLabel}
                    <p>
                        {eventBegins}
                        {eventBegins && eventVenue ? ', ' : ''}
                        {eventVenue}
                    </p>
                </div>
            );
        }
        if (archetypeVariant === 'Fund') {
            const fundLabel = String(data[DONATIONS_FUND]);
            return (
                <span>
                    {t('donationTowardsThe')} {fundLabel}
                </span>
            );
        }
        if (archetypeVariant === 'Merchandise' || archetypeVariant === 'Refreshments') {
            const merchandiseLabel = String(data[MERCHANDISE_PRODUCT_LABEL]);
            const merchandiseDescription = `, ${String(data[MERCHANDISE_PRODUCT_DESCRIPTION])}`;
            return (
                <span>
                    {merchandiseLabel}
                    {merchandiseDescription}
                </span>
            );
        }
        return t('N/A');
    };

    return (
        <TableWrapper>
            <TableFiltersWrapper>
                {availableFilters.map((filter: string, index) => (
                    <React.Fragment key={index}>
                        <TableFilter
                            key={filter}
                            onClick={() => setCurrentFilter(filter)}
                            selected={currentFilter === filter}>
                            {' '}
                            {filterAlias[filter]}
                        </TableFilter>
                        {availableFilters.length !== index + 1 ? <Separator> | </Separator> : null}
                    </React.Fragment>
                ))}
            </TableFiltersWrapper>
            <ASTable
                headers={headers}
                data={tableData}
                showPagination={true}
                sorting={sorting}
                pagination={pagination}
                onChange={onchangePage}
                loading={loading}
                noWidth={true}
            />
            {loading && <LoadingContainer />}
        </TableWrapper>
    );
};

export { EventStatisticsTable };

const TableFiltersWrapper = styled('div')`
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding-top: ${({ theme }) => theme.space.single};
    padding-right: ${({ theme }) => theme.space.single};
`;

const TableFilter = styled('span')<{ selected: boolean }>`
    color: ${({ theme, selected }) => (selected ? theme.colors.text : theme.colors.textLink)};
    text-decoration: ${({ selected }) => (selected ? 'none' : 'underline')};
    cursor: pointer;
`;

const Separator = styled('span')`
    padding: 0px ${({ theme }) => theme.space.half};
`;

const TableWrapper = styled('div')`
    position: relative;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    border: 1px solid ${({ theme }) => theme.colors.frame};
`;
