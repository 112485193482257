import React from 'react';
import { path, pathOr, propOr } from 'ramda';
import {
    EmailSettings,
    FeatureFlags,
    MixpanelSettings,
    settingsStore
} from '../../stores/settings';
import { activityStream } from 'themes/activitystream';
import { ASTheme } from 'themes/styled';
import { OnDone } from 'Authentication/bootstrap/Bootstrap';
import {
    ASMapSettings,
    ProvisioningAPI,
    TenantSettings
} from 'utils/api/provisioning/ProvisioningAPI';
import { userMgmtApi } from 'utils/api/userManagement/UserManagementAPI';
import { AppMetadata, User } from 'utils/api/userManagement/types';
import { deepBlue } from 'themes/deep-blue';
import { darkMode } from 'themes/darkmode';
import { DateFormatContext } from 'components/common/datepicker/DateFormatContext';
import { setGlobalLanguage } from 'utils/localization';
import { AmountSpentBucketsDimension } from 'stores/dimensionData';

const themeNameToTheme: { [key: string]: ASTheme } = {
    darkMode: darkMode,
    deepBlue: deepBlue,
    activityStream: activityStream
};

interface UserSettingsProps {
    onDone: OnDone;
}

const UserSettings = ({ onDone }: UserSettingsProps) => {
    const userSettings = React.useContext(settingsStore);
    const { setDateFnsLocale } = React.useContext(DateFormatContext);

    const setFeatureFlags = (featureFlags: FeatureFlags) => {
        userSettings.dispatch({ type: 'setFeatureFlags', payload: featureFlags });
    };

    const setTheme = (theme: ASTheme) => {
        userSettings.dispatch({ type: 'setTheme', payload: theme });
    };

    const setDictionary = (dictionary: { [key: string]: string }) => {
        userSettings.dispatch({ type: 'setDictionary', payload: dictionary });
    };

    const setTenantIcon = (tenantIcon: string) => {
        userSettings.dispatch({ type: 'setTenantIcon', payload: tenantIcon });
    };

    const setEmailSettings = (emailSettings: EmailSettings) => {
        userSettings.dispatch({ type: 'setEmailSettings', payload: emailSettings });
    };

    const setMixpanelSettings = (mixpanelSettings: MixpanelSettings) => {
        userSettings.dispatch({ type: 'setMixpanelSettings', payload: mixpanelSettings });
    };

    const setAvailableDashboards = (availableDashboards: string[]) => {
        userSettings.dispatch({ type: 'setAvailableDashboards', payload: availableDashboards });
    };

    const setAmountSpentBucketsDimension = (
        amountSpentBucketDimension: AmountSpentBucketsDimension
    ) => {
        userSettings.dispatch({
            type: 'setAmountSpentBucketsDimension',
            payload: amountSpentBucketDimension
        });
    };

    const setMapSettings = (mapSettings: ASMapSettings) => {
        userSettings.dispatch({ type: 'setMapSettings', payload: mapSettings });
    };

    const setAvailableService = (availableService: string[]) => {
        userSettings.dispatch({ type: 'setAvailableService', payload: availableService });
    };

    const setTimeZone = (timeZone: string) => {
        userSettings.dispatch({ type: 'setTimeZone', payload: timeZone });
    };

    const setTenant = (tenant: string) => {
        userSettings.dispatch({ type: 'setTenant', payload: tenant });
    };

    const setTenantDisplayName = (tenantDisplayName: string) => {
        userSettings.dispatch({ type: 'setTenantDisplayName', payload: tenantDisplayName });
    };

    const setSubtenants = (subtenants: { [key: string]: string }) => {
        userSettings.dispatch({ type: 'setSubtenants', payload: subtenants });
    };

    const setPartitions = (partitions: { [key: string]: string }) => {
        userSettings.dispatch({ type: 'setPartitions', payload: partitions });
    };

    const setPii = (pii: boolean) => {
        userSettings.dispatch({ type: 'setPii', payload: pii });
    };

    const setCurrentUser = (currentUser: User) => {
        userSettings.dispatch({ type: 'setCurrentUser', payload: currentUser });
    };

    const setEnv = (env: string) => {
        userSettings.dispatch({ type: 'setEnv', payload: env });
    };

    const setExcludeDashboardComponent = (excludeDashboardComponent: string[]) => {
        userSettings.dispatch({
            type: 'setExcludeDashboardComponent',
            payload: excludeDashboardComponent
        });
    };

    const setExcludeSegments = (excludeSegments: string[]) => {
        userSettings.dispatch({
            type: 'setExcludeSegments',
            payload: excludeSegments
        });
    };

    const setSparkpostSendingDomains = (excludeSegments: string[]) => {
        userSettings.dispatch({
            type: 'setSparkpostSendingDomains',
            payload: excludeSegments
        });
    };

    const setCurrentUserRoles = (currentUserRoles: string[]) => {
        userSettings.dispatch({
            type: 'setCurrentUserRoles',
            payload: currentUserRoles
        });
    };

    const setCurrentUserScope = (currentUserScope: string[]) => {
        userSettings.dispatch({
            type: 'setCurrentUserScope',
            payload: currentUserScope
        });
    };

    const setDefaultDashboard = (defaultDashboard: string) => {
        userSettings.dispatch({
            type: 'setDefaultDashboard',
            payload: defaultDashboard
        });
    };

    React.useEffect(() => {
        Promise.all([
            ProvisioningAPI.getResolvedSettings(),
            userMgmtApi.getCurrentUserAppMetadata(),
            userMgmtApi.getUser()
        ]).then(([settings, { tenants }, user]: [TenantSettings, AppMetadata, User]) => {
            const currentUser = user;
            const tenant = settings.tenant;
            const tenantDisplayName = settings.tenantDisplayName || settings.tenant;
            const privilege = tenants?.find((priv) => priv.tenant === tenant);
            const defaultDashboard: string | undefined = privilege && privilege.default_dashboard;
            const currentTheme = pathOr('activityStream', ['dictionary', 'currentTheme'], settings);
            settings.featureFlags = settings.featureFlags ? settings.featureFlags : [];
            const mapSettings: ASMapSettings | undefined = path(['settings', 'ASMap'], settings);
            const emailSettings: EmailSettings | undefined = path(['settings', 'email'], settings);
            const dictionary = propOr<
                { [key: string]: string },
                TenantSettings,
                { [key: string]: string }
            >({}, 'dictionary', settings);
            const mixpanelSettings: MixpanelSettings | undefined = path(
                ['settings', 'mixpanelSettings'],
                settings
            );
            const tenantIcon: string | undefined = path(['settings', 'tenantIcon'], settings);
            const env: string | undefined = path(['env'], settings);
            setFeatureFlags({
                useSply: propOr(
                    true,
                    'isEnabled',
                    settings.featureFlags.find((featureFlag) => featureFlag.name === 'useSply')
                ),
                showInventory: propOr(
                    true,
                    'isEnabled',
                    settings.featureFlags.find(
                        (featureFlag) => featureFlag.name === 'showInventory'
                    )
                ),
                useMiles: propOr(
                    true,
                    'isEnabled',
                    settings.featureFlags.find((featureFlag) => featureFlag.name === 'useMiles')
                ),
                showProductionKPI: propOr(
                    true,
                    'isEnabled',
                    settings.featureFlags.find(
                        (featureFlag) => featureFlag.name === 'showProductionKPI'
                    )
                ),
                showResales: propOr(
                    true,
                    'isEnabled',
                    settings.featureFlags.find((featureFlag) => featureFlag.name === 'showResales')
                ),
                shortListTopList: propOr(
                    false,
                    'isEnabled',
                    settings.featureFlags.find(
                        (featureFlag) => featureFlag.name === 'shortListTopList'
                    )
                ),
                complimentary: propOr(
                    false,
                    'isEnabled',
                    settings.featureFlags.find(
                        (featureFlag) => featureFlag.name === 'complimentary'
                    )
                ),
                allowExport: propOr(
                    true,
                    'isEnabled',
                    settings.featureFlags.find((featureFlag) => featureFlag.name === 'allowExport')
                ),
                allowScopedUsersExport: propOr(
                    false,
                    'isEnabled',
                    settings.featureFlags.find(
                        (featureFlag) => featureFlag.name === 'allowScopedUsersExport'
                    )
                ),
                inlineManual: propOr(
                    true,
                    'isEnabled',
                    settings.featureFlags.find((featureFlag) => featureFlag.name === 'inlineManual')
                ),
                disablePeriodSelector: propOr(
                    false,
                    'isEnabled',
                    settings.featureFlags.find(
                        (featureFlag) => featureFlag.name === 'disablePeriodSelector'
                    )
                ),
                showUplifts: propOr(
                    true,
                    'isEnabled',
                    settings.featureFlags.find((featureFlag) => featureFlag.name === 'showUplifts')
                ),
                showCustomersTable: propOr(
                    true,
                    'isEnabled',
                    settings.featureFlags.find(
                        (featureFlag) => featureFlag.name === 'showCustomersTable'
                    )
                ),
                showFocusedButton: propOr(
                    true,
                    'isEnabled',
                    settings.featureFlags.find(
                        (featureFlag) => featureFlag.name === 'showFocusedButton'
                    )
                ),
                showProductSalesOverviewProductDropdowns: propOr(
                    false,
                    'isEnabled',
                    settings.featureFlags.find(
                        (featureFlag) =>
                            featureFlag.name === 'showProductSalesOverviewProductDropdowns'
                    )
                ),
                eventBlocks: propOr(
                    false,
                    'isEnabled',
                    settings.featureFlags.find((featureFlag) => featureFlag.name === 'eventBlocks')
                ),
                defaultDateRangeEndOfMinute: propOr(
                    false,
                    'isEnabled',
                    settings.featureFlags.find(
                        (featureFlag) => featureFlag.name === 'defaultDateRangeEndOfMinute'
                    )
                ),
                realTimeAudienceRewards: propOr(
                    true,
                    'isEnabled',
                    settings.featureFlags.find(
                        (featureFlag) => featureFlag.name === 'realTimeAudienceRewards'
                    )
                ),
                hubspotReports: propOr(
                    true,
                    'isEnabled',
                    settings.featureFlags.find(
                        (featureFlag) => featureFlag.name === 'hubspotReports'
                    )
                ),
                hideNotOptIn: propOr(
                    false,
                    'isEnabled',
                    settings.featureFlags.find((featureFlag) => featureFlag.name === 'hideNotOptIn')
                ),
                showGroupedSalesByCampaign: propOr(
                    false,
                    'isEnabled',
                    settings.featureFlags.find(
                        (featureFlag) => featureFlag.name === 'showGroupedSalesByCampaign'
                    )
                )
            });
            if (tenantIcon) {
                setTenantIcon(tenantIcon);
            }
            setTenant(tenant);
            setTenantDisplayName(tenantDisplayName);
            setTheme(themeNameToTheme[String(currentTheme)] || activityStream);
            setDictionary(dictionary);
            setGlobalLanguage(
                pathOr('en-US', ['settings', 'locale'], settings),
                dictionary,
                setDateFnsLocale,
                userSettings
            );
            if (mapSettings) {
                setMapSettings(mapSettings);
            }
            if (emailSettings) {
                setEmailSettings(emailSettings);
            }
            if (mixpanelSettings) {
                setMixpanelSettings(mixpanelSettings);
            }
            setTimeZone(
                propOr(Intl.DateTimeFormat().resolvedOptions().timeZone, 'tenantTimezone', settings)
            );
            setAvailableDashboards(propOr([], 'availableDashboards', settings));
            setAmountSpentBucketsDimension(
                pathOr('amountSpentBuckets250', ['settings', 'amountSpentBuckets'], settings)
            );
            setAvailableService(propOr([], 'availableService', settings));
            setExcludeDashboardComponent(propOr([], 'excludeDashboardComponent', settings));
            setExcludeSegments(propOr([], 'excludeSegments', settings));
            setSparkpostSendingDomains(propOr([], 'sparkpostSendingDomains', settings));
            setCurrentUserRoles(propOr([], 'roles', privilege));
            setPii(propOr(false, 'pii', privilege));
            setCurrentUserScope(propOr([], 'entities', privilege));
            setSubtenants(
                propOr<
                    Array<{ key: string; value: string }>,
                    TenantSettings,
                    Array<{ key: string; value: string }>
                >([], 'subtenants', settings).reduce(
                    (p, { key, value }) => {
                        p[key] = value;
                        return p;
                    },
                    {} as { [key: string]: string }
                )
            );
            setPartitions(
                propOr<
                    Array<{ key: string; value: string }>,
                    TenantSettings,
                    Array<{ key: string; value: string }>
                >([], 'partitions', settings).reduce(
                    (p, { key, value }) => {
                        p[key] = value;
                        return p;
                    },
                    {} as { [key: string]: string }
                )
            );
            setCurrentUser(currentUser);
            if (defaultDashboard) {
                setDefaultDashboard(defaultDashboard);
            }
            if (env) {
                setEnv(env);
            }
            onDone(true);
        });
    }, []);

    return <React.Fragment />;
};

export { UserSettings };
